import React, { useState, useRef, useEffect } from 'react';

const CommandPromptPageEastLondon = () => {
  const [inputPassword, setInputPassword] = useState('');
  const [accessGranted, setAccessGranted] = useState(false);
  const [attemptHistory, setAttemptHistory] = useState([]);
  const inputRef = useRef(null);
  const correctPassword = 'firsttechnology'; // Replace with your actual password

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputPassword.toLowerCase() === correctPassword) {
      setAccessGranted(true);
      setTimeout(() => {
        window.location.href = 'https://firstespionage.scenes-app.co.za/eastlondon'; // Replace with your destination URL
      }, 2000); // Redirect after a short delay
    } else {
      setAttemptHistory([...attemptHistory, inputPassword]);
      setInputPassword(''); // Clear the input for the next attempt
    }
  };

  useEffect(() => {
    // Focus the input on initial render
    if(inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleContainerClick = () => {
    // Focus the input when the container is clicked
    if(inputRef.current) {
      inputRef.current.focus();
    }
  };

  const renderHistory = () => {
    return attemptHistory.map((attempt, index) => (
      <div key={index}>
        - {attempt} - Access Denied.
      </div>
    ));
  };

  const commandPromptStyle = {
    backgroundColor: 'black',
    color: '#00FFFF', // Cyan color
    fontFamily: 'VCR_OSD_MONO, monospace', // Add the custom font here
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    fontSize: '1rem', // Larger font size
    lineHeight: '1.5',
    cursor: 'text', // Change the cursor to indicate the area is clickable
    width: '50%',
    height: '100%',
    textAlign: 'center',
  };

  const inputStyle = {
    backgroundColor: 'transparent',
    color: '#00FFFF', // Cyan color
    border: 'none',
    borderBottom: '1px solid #00FFFF',
    fontSize: '1rem',
    fontFamily: 'VCR_OSD_MONO, monospace', // Add the custom font here
    outline: 'none',
    textAlign: 'start',
  };

  return (
    <div style={{backgroundColor: 'black', justifyContent:'center', alignItems:'center', display:'flex'}}>
        <div style={commandPromptStyle} onClick={handleContainerClick}>
        <div>
          <div style={{fontSize:40, textAlign:'start'}}>East London</div>
            <img src="..//img/espionage.png"></img>
            <div style={{fontSize:40}}>Unlock your RSVP</div>
            <div style={{fontSize:24 }}>Crack the code using the key on the app.</div>
            <div style={{color:'white'}}>You can download the app using the link in your invite email.</div>
            <br></br>
            <div style={{fontSize:20 }}>6 9 18 19 20 20 5 3 8 14 15 12 15 7 25</div>
            <br></br>
            {accessGranted ? (
            <div>- Access Granted... Redirecting...</div>
            ) : (
            <>
            <div style={{textAlign:'start',}}>
                {renderHistory()}
                - Enter password:
                <form onSubmit={handleSubmit} style={{ display: 'inline' }}>
                <input
                    ref={inputRef}
                    autoFocus
                    type="text"
                    value={inputPassword}
                    onChange={(e) => setInputPassword(e.target.value)}
                    style={inputStyle}
                />
                </form>
            </div>
                
            </>
            )}
        </div>
        </div>
      </div>
    
  );
};

export default CommandPromptPageEastLondon;
