import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { arrayUnion, collection, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import toast, { Toaster } from 'react-hot-toast';

const EventRegister = () => 
{ 
  const { eventId } = useParams();
  const [eventDetails, setEventDetails] = useState(null);

  const docRef = doc(db, 'events', eventId);

  // Fetch event details from Firestore using the eventId
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = getFirestore();
      const eventRef = doc(dbRef, 'events', eventId); // Replace 'eventID' with your actual event ID
      const eventSnap = await getDoc(eventRef);

      if (eventSnap.exists()) {
        setEventDetails(eventSnap.data());
      } else {
        console.log('No such document!');
        toast('No such event!');
      }
    };

    fetchData();
  }, [eventId]);

  const [formData, setFormData] = useState({ firstName: '', lastName: '', companyName: '', email: '', password: '' });
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.password !== e.target.confirmPassword.value) {
      toast("Passwords do not match!");
      return;
    }
  
    const auth = getAuth();
    const dbRef = getFirestore();
  
    try {
      // Try to sign in with the provided credentials
      const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
  
      // Update the user's information since the user exists and the password matches
      await setDoc(doc(dbRef, 'users', user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.companyName,
        email: formData.email,
        uid: user.uid,
      }, { merge: true });  // Merges with existing data
  
      // Update the RSVP for the new event
      await setDoc(doc(dbRef, 'RSVP-MTL23', uuidv4()), {
        recipientName: formData.firstName,
        email: formData.email,
        uid: user.uid,
      });
  
      const userRef = doc(dbRef, 'users', user.uid);
      const eventRef = doc(dbRef, 'events', eventId);
  
      await updateDoc(userRef, {
        eventsAttending: arrayUnion(eventId),
      });
  
      await updateDoc(eventRef, {
        attendees: arrayUnion(user.uid),
      });
  
      confirmationNotify();
  
      // Clear the form
      e.target.confirmPassword.value = '';
      setFormData({ 
        firstName: '', 
        lastName: '', 
        companyName: '', 
        email: '', 
        password: '' 
      });
      
    } catch (signInError) {
    
      // If wrong password for existing user
      if (signInError.code === "auth/wrong-password") {
        toast('Account already created for another event, please use the same password');
        return;
      }
    
      // If the account does not exist
      if (signInError.code === "auth/user-not-found") {
    
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
          const user = userCredential.user;
    
          // Store the user's details in Firestore
          await setDoc(doc(dbRef, 'users', user.uid), {
            firstName: formData.firstName,
            lastName: formData.lastName,
            companyName: formData.companyName,
            email: formData.email,
            uid: user.uid,
          });
    
          await setDoc(doc(dbRef, 'RSVP-MTL23', uuidv4()), {
            recipientName: formData.firstName,
            email: formData.email,
            uid: user.uid,
          });
    
          const userRef = doc(dbRef, 'users', user.uid);
          const eventRef = doc(dbRef, 'events', eventId);
    
          await updateDoc(userRef, {
            eventsAttending: arrayUnion(eventId),
          });
    
          await updateDoc(eventRef, {
            attendees: arrayUnion(user.uid),
          });
    
          confirmationNotify();
    
          // Clear the form
          e.target.confirmPassword.value = '';
          setFormData({ 
            firstName: '', 
            lastName: '', 
            companyName: '', 
            email: '', 
            password: '' 
          });
    
        } catch (createUserError) {
          console.log(createUserError.message);
          toast(createUserError.message);
        }
    
      } else {
        // For other errors during sign-in
        console.log(signInError.message);
        toast(signInError.message);
      }
    }
  };
  

  const confirmationNotify = () => toast('RSVP & Registration successful!');

  return(
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12" style={{backgroundColor: "#0D0F1C"}}>
      <div className="relative py-3 sm:max-w-3xl sm:mx-auto sm:w-8/12">
        <div className='flex justify-center items-center'>
          <img className='scale-100 pb-1' src="..//img/firsttech-logo.png" />
        </div>
        
        <div className='flex justify-center items-center'>
          <img className='scale-100 p-1' src="..//img/event-bg-incl.png" />
        </div>
        
        <div className='flex justify-center items-center pt-2 mb-10 pb-8'>
          <a className="b mx-auto  w-1/2 h-15 flex justify-center items-center i h-14 bg-gradient-to-br from-white to-white items-center rounded-full border border-white-600 shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out text-center text-black font-semibold" href="#register-form" >Register</a>
        </div>
        
      </div>
      <div className="relative py-10 " style={{backgroundColor: "#ffffff"}}>
        <div className="text-center ">
          <div className='my-5'>
            <h1 className="text-4xl text-black font-bold tech-heading">THE TECH CONFERENCE</h1>
            <div className='flex justify-center items-center px-5 py-10'>
              <p className='text-xl lg:w-1/2 w-10/12'>
              Join us as we showcase both our services and our products and solutions to the A-List of the Technology Industry.  Network. Develop. Advance.
              </p>
            </div>
            
          </div>
        </div>
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-rows-1 md:grid-rows-2" style={{backgroundColor: "#0D0F1C"}}>
        <div className='event-side-tab-gradient-one'>
          <div className="relative py-3 px-1 sm:max-w-3xl sm:mx-auto 2xl:w-5/12 w-7/12">
            <div className='flex justify-start items-start px-2 mt-10'>
              <p className='text-xs mt-5 text-white' style={{fontWeight:900}}>MTL23</p>
            </div>
            <div className='flex justify-start items-start px-2 py-1'>
              <h4 className="text-4xl text-black font-bold text-white tech-heading">NETWORK.</h4>
            </div>
            <div className='flex justify-start items-start px-2 py-1'>
              <h4 className="text-4xl text-black font-bold text-white tech-heading">DEVELOP.</h4>
            </div>
            <div className='flex justify-start items-start px-2 py-1'>
              <h4 className="text-4xl text-black font-bold text-white tech-heading">ADVANCE.</h4>
            </div>
            <div className='flex justify-start items-start py-5 px-2'>
              <p className='text-white text-md
              '>
              Experience how First Technology KZN is transforming technology and innovation to become nationally competitive at the cutting edge Umhlanga Arch Convention Centre between 11:00 - 17:00 on Tuesday, 10 October 2023.
              </p>
            </div>
          </div>
        </div>
        <div>
          <img className='object-cover w-full h-full' src="..//img/umhlanga-arch.png" />
        </div>
      </div>
      <div className="grid sm:grid-cols-3 lg:grid-rows-1 md:grid-rows-2" style={{backgroundColor: "#0D0F1C"}}>
        <div>
          <img className='object-cover w-full h-full' src="..//img/people-speaking.png" />
        </div>
        <div className='event-side-tab-gradient-two'>
          <div className="relative py-3 px-1 sm:max-w-3xl sm:mx-auto 2xl:w-5/12 w-7/12">
            <div className='flex justify-start items-start px-2 py-1 mt-10'>
              <p className='text-xs mt-5 text-white' style={{fontWeight:900}}>MTL23</p>
            </div>
            <div className='flex justify-start items-start px-2 py-1'>
              <h4 className="text-4xl text-black font-bold text-white tech-heading">ENGAGE.</h4>
            </div>
            <div className='flex justify-start items-start py-5 px-2'>
              <p className='text-white text-md
              '>
              As First Technology KZN, we value you, our esteemed customers and as such we invite you to engage in the conference products, solutions and services to the A-List of the technology industry.
              </p>
            </div>
          </div>
        </div>
        <div className='hidden lg:block'>
          <img className='object-cover w-full h-full' src="..//img/person-speaking.png" />
        </div>
      </div>
      <div className="grid sm:grid-cols-4 lg:grid-rows-1 md:grid-rows-2" style={{backgroundColor: "#0D0F1C"}}>
        <div>
          <img className='object-cover w-full h-full' src="..//img/person-vr-headset.png" />
        </div>
        
        <div className='event-side-tab-gradient-three'>
          <div className="relative py-3 px-1 sm:max-w-3xl sm:mx-auto 2xl:w-6/12 w-9/12">
            <div className='flex justify-start items-start px-2 py-1 mt-10'>
              <p className='text-xs mt-5 text-white' style={{fontWeight:900}}>MTL23</p>
            </div>
            <div className='flex justify-start items-start px-2 py-1'>
              <h4 className="text-4xl text-black font-bold text-white tech-heading">WE MAKE TECH LIT.</h4>
            </div>
            <div className='flex justify-start items-start py-5 px-2'>
              <p className='text-white text-md
              '>
              Industry speakers. Virtual reality. Mirror men. Indulge in the convergence of technology and culture with the finest visual acts as part of our immersive, sensory experience.
              </p>
            </div>
          </div>
        </div>
        <div className='hidden lg:block'>
          <img className='object-cover w-full h-full' src="..//img/mirror-men.png" />
        </div>
        <div className='hidden lg:block'>
          <img className='object-cover w-full h-full' src="..//img/people-dancing-in-air.png" />
        </div>
      </div>
      <div className="relative py-3 sm:max-w-3xl sm:mx-auto sm:w-4/12">
        <div className="relative px-4 pb-5 pt-10">
          <div className="max-w-3xl mx-auto text-center ">
            <div>
              <h1 className="text-4xl text-slate-100 font-bold tech-heading">USER REGISTRATION</h1>
            </div>
            <div>
              <h4 className="text-base text-slate-100 font-semibold pt-3">Enter your details below</h4>
            </div>
            <section id="register-form">
              <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div className="flex space-x-4">
                    <div className="relative bg-white shadow-lg rounded-xl p-4 w-1/2">
                      <input onChange={handleChange} value={formData.firstName} required autoComplete="off" id="firstName" name="firstName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="First Name" />
                      <label htmlFor="firstName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">First Name</label>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-4 w-1/2">
                      <input onChange={handleChange} value={formData.lastName} required autoComplete="off" id="lastName" name="lastName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Last Name" />
                      <label htmlFor="lastName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Last Name</label>
                    </div>
                  </div>
                  <div className="relative bg-white shadow-lg rounded-xl p-4">
                    <input onChange={handleChange} value={formData.companyName} required autoComplete="off" id="companyName" name="companyName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Company Name" />
                    <label htmlFor="companyName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Company Name</label>
                  </div>
                  <div className="relative bg-white shadow-lg rounded-xl p-4">
                    <input onChange={handleChange} value={formData.email} required autoComplete="off" id="email" name="email" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Company Email address" />
                    <label htmlFor="email" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Company Email Address</label>
                  </div>
                  <div className="relative bg-white shadow-lg rounded-xl p-4">
                    <input onChange={handleChange} value={formData.password} required autoComplete="off" id="password" name="password" type="password" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
                    <label htmlFor="password" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Password</label>
                  </div>
                  <div className="relative bg-white shadow-lg rounded-xl p-4">
                    <input required autoComplete="off" id="confirmPassword" name="confirmPassword" type="password" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Confirm Password" />
                    <label htmlFor="confirmPassword" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Confirm Password</label>
                  </div>
                  <div className='flex justify-center items-center pt-7'>
                    <button className="b mx-auto w-1/2 h-15 flex justify-center items-center i h-14 bg-gradient-to-br from-transparent to-transparent items-center rounded-full border border-white-600 shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out text-center text-white font-semibold" type="submit">Register</button>
                  </div>
                </div>
              </form>
            </section>
            
            <Toaster />
          </div>
          <div className='flex justify-center items-center mt-5 p-5'>
            <img className='scale-50 p-0.5' src="..//img/created-by.png" />
          </div>
        </div>
      </div>
    </div>
    );
    
};

export default EventRegister;