import React, { useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './admin.css'
import { Route, Routes, Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthContext } from '../../components/providers/authprovider';


const AdminContext = React.createContext();

    export const useSelectedEvent = () => {
      return useContext(AdminContext);
    };

const Admin = () => {
    

    const isAdmin = useContext(AuthContext);
    const [fetchedData, setFetchedData] = useState(false);
    const location = useLocation(); // once ready it returns the 'window.location' object
    const navigate = useNavigate();

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState("ce04bafa-e436-4207-9cc1-402f731546be");

    const auth = getAuth();

    useEffect(() => {
        

        const fetchUserDataAndEvents = async () => {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
                const userEvents = userDoc.data().eventsAttending;
                const eventsData = [];
                
                for (let eventId of userEvents) {
                    const eventDocRef = doc(db, 'events', eventId);
                    const eventDoc = await getDoc(eventDocRef);
                    
                    
                    if (eventDoc.exists() && eventDoc.data().admin_uids.includes(auth.currentUser.uid)) {
                        eventsData.push(eventDoc.data());
                    }
                }
                setEvents(eventsData);
                setFetchedData(true);
            }
        };

        onAuthStateChanged(auth, (user) => {
            if(user)
            {
                fetchUserDataAndEvents();
                console.log(user.uid);
            }
        });

    }, []);

    const [url, setUrl] = useState(null);

    useEffect(() => {
        setUrl(location.pathname);
    }, [location]);

    const SignOut = async () => {
        signOut(auth).then(() => {
            navigate('/', { replace: true });
        }).catch((error) => {
        // An error happened.
        });
    }

    return (
    
    <div className="container-fluid">
        {isAdmin ? (
        <div className="row flex-nowrap">
            <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 admin-sidebar">
                <div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                {fetchedData ? (
                    <span>
                        <ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                            <li>
                                <Link to="/admin/waitlist" className={"nav-link px-0 align-middle" + (url === "/admin/waitlist" ?" active" : "")}>
                                    <i className="fa-solid fa-house"></i> <span className="ms-1 d-none d-sm-inline">Waitlist</span>
                                </Link>
                            </li>
                        </ul>
                        <hr />
                        <div className="dropdown pb-4">
                            {events.length > 0 && (
                                <select onChange={e => setSelectedEvent(e.target.value)} className="d-flex align-items-center text-decoration-none dropdown-toggle">
                                    {events.map(event => (
                                        <option className="d-none d-sm-inline mx-1"  key={event.eventId} value={event.eventId}>{event.eventName}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="dropdown pb-4">
                            <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownAdmin" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="d-none d-sm-inline mx-1">Admin</span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownAdmin">
                                <li><a className="dropdown-item" href="#" onClick={SignOut}>Sign out</a></li>
                            </ul>
                        </div>
                    </span>): 
                    (<div style={{marginTop:50, marginLeft:50, justifyContent: 'center', display: 'flex'}}>Loading</div>)}
                </div>
            </div>
            <div className="col py-3">
            <AdminContext.Provider value={{ selectedEvent, setSelectedEvent }}>
                <Outlet />
            </AdminContext.Provider>
            </div>
        </div>
        ):(<div>
            <p>403 - Unauthorized</p>
        </div>)}
    </div>
  );
};

export default Admin;