import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getDatabase, ref, set } from 'firebase/database';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useSelectedEvent } from './admin';


const WaitList = () => {
  // Get a reference to the database
  const { selectedEvent, setSelectedEvent } = useSelectedEvent();
  const [usersList, setUsersList] = useState({});
  const [fetchedData, setFetchedData] = useState(false);

  useEffect(() => {
    const fetchWaitListUsers = async () => {
      if (selectedEvent) {
          const db = getFirestore();
          const eventDocRef = doc(db, 'events', selectedEvent);
          const eventDoc = await getDoc(eventDocRef);
  
          if (eventDoc !== null) {
              const waitlistUIDs = eventDoc.data().waitList || [];
              const usersData = [];
  
              for (let uid of waitlistUIDs) {
                  const userDocRef = doc(db, 'users', uid);
                  const userDoc = await getDoc(userDocRef);
                  if (userDoc.exists()) {
                      usersData.push({ ...userDoc.data(), uid }); // appending uid for reference
                  }
              }
              setUsersList(usersData);
              setFetchedData(true);
          }
      }
    };
  
    fetchWaitListUsers();
    
}, []);

const moveToAttendee = async (userUid) => {
  const db = getFirestore();
  const eventDocRef = doc(db, 'events', selectedEvent);
  const eventDoc = await getDoc(eventDocRef);
  
  if (eventDoc.exists()) {
      const eventData = eventDoc.data();
      const waitlist = eventData.waitlist || [];
      const attendees = eventData.attendees || [];
      
      const userIndex = waitlist.findIndex(uid => uid === userUid);

      if (userIndex !== -1) {
          waitlist.splice(userIndex, 1);
          attendees.push(userUid);

          await updateDoc(eventDocRef, {
              waitlist,
              attendees
          });

          // Refresh the local state or re-fetch data
          setUsersList(waitlist);
      }
  }
};

  const columns = [
    {
        name: 'UID',
        cell: row => `${row.uid.slice(0, 20)}...`,
        wrap: true,
        hide: 'lg',
    },
    {
        name: 'First Name',
        selector: row => row.firstName,
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: row => row.lastName,
        sortable: true,
        hide: 'lg',
    },
    {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
    },
    {
      name: 'Company',
      selector: row => row.companyName,
      sortable: true,
    },
    {
      name: '',
      cell: row => {return(<button className='btn btn-rounded-admin btn-table' onClick={() => moveToAttendee(row.uid)}>Move to Attendee</button>);}

    },
  ];

  
    

  return (
    <div className="users">
      <div className='row'>
        <h3>Wait List</h3>
      </div>
      {fetchedData ? (
        <div>
          <div className='row m-1'>
            
          </div>
        
        <DataTable
            columns={columns}
            data={usersList}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[10,15]}
            responsive
        />
        </div>
      ) : (<div style={{marginTop:50, justifyContent: 'center', display: 'flex'}}>Loading</div>)}
    </div>
  );
};

export default WaitList;