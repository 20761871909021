import React, { useEffect, useState } from 'react';
import { getAuth, signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import toast, { Toaster } from 'react-hot-toast';

const EventChallenge = () => {
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState(null);
  const [challengeDetails, setChallengeDetails] = useState(null);

  const navigate = useNavigate();

  const { eventId, challengeId } = useParams();

  const docRef = doc(db, 'events', eventId);
  
  // Fetch event details from Firestore using the eventId
  useEffect(() => {
    const fetchData = async () => {
      const dbRef = getFirestore();
      const eventRef = doc(dbRef, 'events', eventId); // Replace 'eventID' with your actual event ID
      const eventSnap = await getDoc(eventRef);

      if (eventSnap.exists()) {
        const challengeRef = doc(dbRef, `events/${eventId}/challenges`, challengeId);
          if (challengeRef) {
            const challengeSnap = await getDoc(challengeRef);
            setChallengeDetails(challengeSnap.data());
            console.log('Challenge details:', challengeSnap.data());
          } else {
            console.log('No such challenge!');
          }
      } else {
        console.log('No such document!');
      }
    };

    fetchData();
  }, [eventId, challengeId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();

    try {
      await signInAnonymously(auth);

      const challengeAnswerData = {
        firstName: firstName,
        phone: phone,
        answer: answer,
        timestamp: new Date().toISOString(),
        challengeId: challengeId,
      };

      try {
        const db = getFirestore();
        const docRef = collection(db, 'challengeAnswers');
        
        // Query Firestore to check if an answer with the same phone and challengeId already exists
        const querySnapshot = await getDocs(query(docRef, where("phone", "==", phone), where("challengeId", "==", challengeId)));
        
        if (!querySnapshot.empty) {
          console.log('An answer has already been submitted with this phone number for this challenge.');
          toast.error('An answer has already been submitted with this phone number for this challenge.');
          return;
        }
  
        const docSnap = await addDoc(docRef, challengeAnswerData);
        const docId = docSnap.id;
        console.log('Answer submitted with ID:', docId);
        setAnswer('');
        setPhone('');
        setFirstName('');
        toast.success('Answer submitted successfully!', {position: 'bottom-center'});
      } catch (error) {
        console.error('Error submitting answer:', error);
        toast.error('Error submitting answer! Please try again later.', {position: 'bottom-center'});
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error('Error signing in:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
        <div className="row flex justify-center items-center" >
            <div className="col py-3 justify-center items-center text-center">
              <h2>Answer the challenge below!</h2>
              {challengeDetails && <h3>{challengeDetails.challengeName}</h3>}
              {challengeDetails && <p>{challengeDetails.description}</p>}
              {challengeDetails && <p>{challengeDetails.description2}</p>}
              {challengeDetails && <p>{challengeDetails.description3}</p>}
              {challengeDetails && <p>{challengeDetails.description4}</p>}
              {challengeDetails && <p>{challengeDetails.description5}</p>}
              {challengeDetails && <p>{challengeDetails.description6}</p>}
              {error && <p style={{color: 'red'}}>{error}</p>}
              <form onSubmit={handleSubmit}>
                <div className="relative bg-white shadow-lg rounded-xl p-3 m-2">
                  <label className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Phone:</label>
                  <input className='peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600'
                    type="text" 
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="relative bg-white shadow-lg rounded-xl p-3 m-2">
                  <label className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Name:</label>
                  <input className='peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600'
                    type="text" 
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="relative bg-white shadow-lg rounded-xl p-3 m-2">
                  <label className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Answer:</label>
                  <input className='peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600'
                    type="text" 
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                    required
                  />
                </div>
                <div className='relative'>
                  <button className="b mx-auto m-2 w-full h-12 flex justify-center items-center i h-14 bg-gradient-to-br from-white to-white items-center rounded-full border border-white-600 shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out text-center text-black font-semibold" type="submit">Submit</button>
                </div>
              </form>
            </div>
        </div>
        <Toaster />
    </div>
  );
};

export default EventChallenge;
