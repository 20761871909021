import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { arrayUnion, collection, doc, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import './first-tech-bus-con.css';
import toast, { Toaster } from 'react-hot-toast';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const FirstTech24Conference = () => 
{ 
  const [planetOne, setPlanetOne] = useState(false);
  const [planetTwo, setPlanetTwo] = useState(false);
  const [planetThree, setPlanetThree] = useState(false);

  const [eventDetails, setEventDetails] = useState(null);
  const [eventId, setEventId] = useState("3e941459-9e31-4c53-9d0c-3a5a1ff187f4");
  const [showStarWarsText, setShowStarWarsText] = useState(false);
  
  // Fetch event details from Firestore using the eventId
  useEffect(() => {
    
  const fetchData = async () => {
    const dbRef = getFirestore();
    const eventRef = doc(dbRef, 'events', eventId); // Replace 'eventID' with your actual event ID
    const eventSnap = await getDoc(eventRef);

    if (eventSnap.exists()) {
      setEventDetails(eventSnap.data());
    } else {
      console.log('No such document!');
      toast('No such event!');
    }
  };

  fetchData();
  }, [eventId]);
  

  const [formData, setFormData] = useState({ firstName: '', lastName: '', companyName: '', email: '', dietaryRequirements: '', password: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.password !== e.target.confirmPassword.value) {
      toast("Passwords do not match!");
      return;
    }
  
    const auth = getAuth();
    const dbRef = getFirestore();
  
    try {
      // Try to sign in with the provided credentials
      const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;
  
      // Update the user's information since the user exists and the password matches
      await setDoc(doc(dbRef, 'users', user.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyName: formData.companyName,
        dietaryRequirements: formData.dietaryRequirements,
        email: formData.email,
        uid: user.uid,
      }, { merge: true });  // Merges with existing data
  
      await setDoc(doc(dbRef, 'RSVP-FTech24', uuidv4()), {
        recipientName: formData.firstName,
        email: formData.email,
        uid: user.uid,
      });

      const userRef = doc(dbRef, 'users', user.uid);
      const eventRef = doc(dbRef, 'events', eventId);

      await updateDoc(userRef, {
        eventsAttending: arrayUnion(eventId),
      });

      await updateDoc(eventRef, {
        attendees: arrayUnion(user.uid),
      });

      confirmationNotify();

      // Clear the form
      e.target.confirmPassword.value = '';
      setFormData({ 
        firstName: '', 
        lastName: '', 
        companyName: '', 
        email: '', 
        password: '', 
        dietaryRequirements: ''
      });
      
    } catch (signInError) {
  
      // If wrong password for existing user
      if (signInError.code === "auth/wrong-password") {
        toast('Account already created for another event, please use the same password');
        return;
      }
  
      // If the account does not exist
      if (signInError.code === "auth/user-not-found") {
  
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
          const user = userCredential.user;
  
          // Store the user's details in Firestore
          await setDoc(doc(dbRef, 'users', user.uid), {
            firstName: formData.firstName,
            lastName: formData.lastName,
            companyName: formData.companyName,
            dietaryRequirements: formData.dietaryRequirements,
            email: formData.email,
            uid: user.uid,
          });
  
          await setDoc(doc(dbRef, 'RSVP-FTech24', uuidv4()), {
            recipientName: formData.firstName,
            email: formData.email,
            uid: user.uid,
          });
  
          const userRef = doc(dbRef, 'users', user.uid);
          const eventRef = doc(dbRef, 'events', eventId);
  
          await updateDoc(userRef, {
            eventsAttending: arrayUnion(eventId),
          });
  
          await updateDoc(eventRef, {
            attendees: arrayUnion(user.uid),
          });
  
          confirmationNotify();
  
          // Clear the form
          e.target.confirmPassword.value = '';
          setFormData({ 
            firstName: '', 
            lastName: '', 
            companyName: '', 
            email: '', 
            password: '', 
            dietaryRequirements: ''
          });
  
        } catch (createUserError) {
          console.log(createUserError.message);
          toast(createUserError.message);
        }
  
      } else {
        // For other errors during sign-in
        console.log(signInError.message);
        toast(signInError.message);
      }
    }
  };
  

  const confirmationNotify = () => toast('RSVP & Registration successful!');

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async container => {
      await console.log(container);
  }, []);

  return(
    <div className="min-h-screen flex flex-col justify-center" style={{backgroundColor: "#000"}}>
      <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 60,
                interactivity: {
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: false,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 1,
                        },
                        repulse: {
                            distance: 100,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "out",
                        },
                        random: true,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 20,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 0.9, max: 1.5 },
                    },
                    zIndex:{
                      value: -1,
                    }
                },
                detectRetina: true,
            }}
            />
      <div className='sm:py-28 sm:px-20 star-wars-form-bg' style={{backgroundImage: "url('../img/ftech2024/no grid closer-04.png')"}}>
        <div className="relative sm:px-10 sm:pt-24 mt-24 max-w-3xl sm:mx-auto xl:w-5/12">
          <div className='mt-12 flex justify-center items-center'>
            <img className='scale-100 pb-1' src="../img/ftech2024/Gradient First Impact Version-02 (1).png" />
          </div>
          <div className="relative px-4 pb-5 pt-12">
            <div className="max-w-3xl mx-auto text-center ">
            <div>
                <h1 className="text-3xl text-slate-100 font-bold tech-heading ">BUSINESS CONFERENCE 2024</h1>
              </div>
              <div>
                <h1 className="text-3xl text-slate-100 font-bold tech-heading ">USER REGISTRATION</h1>
              </div>
              <div>
                <h4 className="text-base text-slate-100 font-semibold pt-3">Enter your details below</h4>
              </div>
              <section id="register-form p-2 lg:p-12">
                <form className="divide-y divide-gray-200" onSubmit={handleSubmit}>
                  <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                    <div className="flex space-x-4">
                      <div className="relative bg-white shadow-lg rounded-xl p-3 w-1/2">
                        <input onChange={handleChange} value={formData.firstName} required autoComplete="off" id="firstName" name="firstName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="First Name" />
                        <label htmlFor="firstName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">First Name</label>
                      </div>
                      <div className="relative bg-white shadow-lg rounded-xl p-3 w-1/2">
                        <input onChange={handleChange} value={formData.lastName} required autoComplete="off" id="lastName" name="lastName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Last Name" />
                        <label htmlFor="lastName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Last Name</label>
                      </div>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-3">
                      <input onChange={handleChange} value={formData.companyName} required autoComplete="off" id="companyName" name="companyName" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Company Name" />
                      <label htmlFor="companyName" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Company Name</label>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-3">
                      <input onChange={handleChange} value={formData.email} required autoComplete="off" id="email" name="email" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Company Email address" />
                      <label htmlFor="email" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Email Address</label>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-3">
                      <input onChange={handleChange} value={formData.dietaryRequirements} required autoComplete="off" id="dietaryRequirements" name="dietaryRequirements" type="text" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Dietary Requirements" />
                      <label htmlFor="dietaryRequirements" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Dietary Requirements</label>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-3">
                      <input onChange={handleChange} value={formData.password} required autoComplete="off" id="password" name="password" type="password" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Password" />
                      <label htmlFor="password" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Password</label>
                    </div>
                    <div className="relative bg-white shadow-lg rounded-xl p-3">
                      <input required autoComplete="off" id="confirmPassword" name="confirmPassword" type="password" className="peer placeholder-transparent h-8 w-full text-gray-900 focus:outline-none focus:borer-rose-600" placeholder="Confirm Password" />
                      <label htmlFor="confirmPassword" className="absolute left-3 top-1 text-gray-600 text-xs peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-6 transition-all peer-focus:top-0.5 peer-focus:text-gray-600 peer-focus:text-xs">Confirm Password</label>
                    </div>
                    <div className='flex justify-center items-center pt-7'>
                      <button className="b mx-auto w-8/12 h-15 flex justify-center items-center i h-14 bg-gradient-to-br from-transparent to-transparent items-center rounded-full border border-white-600 shadow-2xl cursor-pointer absolute overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out text-center text-white font-semibold" type="submit">Register</button>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </div>
      </div>
      {!showStarWarsText ? (
        <div className='star-wars-button-container pt-12'>
          <div className='flex justify-center items-center py-2 my-1'>
            <img className='star-wars-planet w-full lg:w-2/4 ' style={{cursor: 'pointer'}} src='../img/ftech2024/Yoda-button-2.png'  onClick={() => setShowStarWarsText(true)} />
          </div>
        </div>) :(
        <div className="relative mt-12 sm:max-w-12xl sm:mx-auto sm:w-8/12 w-12/12">
          <div className='star-wars-section'>
            <div class="fade-star-wars"></div>
            <section class="star-wars">
              <div class="crawl">
                <div class="title">
                  <h1>One Vision. One Force.</h1>
                </div>
                
                <p>One of the core objectives of the event is to leverage the expertise and services offered by each of our divisions.  Each division plays a crucial role in our journey and by working together, we have the potential to elevate our powerhouse offering to both existing and new customers.  Our diverse strengths, when combined, will create a unified force to propel us forward in the competitive landscape we operate in.</p>
                  
                <p>The conference will allow each division the opportunity to showcase their remarkable and specialised proficiencies, whilst serving as a platform to highlight the unique contributions of every business unit.  In doing so, we not only celebrate our individual accomplishments but also install a deep understanding of the collective impact we can make as a group.</p>

                <p>Moreover, the conference presents an ideal setting to explore and forge collaborative opportunities within our organisation.  By promoting cross-affiliate collaborations, we open doors to new ventures and possibilities that may have remained unexplored otherwise.  Together, we can unlock untapped potential, create synergies and deliver an unparalleled experience to our clients.</p>
              
                <p>‘For my ally is The Force, and a powerful ally it is.’ - Yoda</p>
              </div>
            </section>
          </div>
        </div> )}
        
          <div className='w-full h-[1200px] relative flex hidden lg:block'>
            <img className='star-wars-planet absolute top-64 left-64 ' src="../img/ftech2024/planet-1.png" onMouseEnter={() => setPlanetOne(true)} onMouseLeave={() => setPlanetOne(false)} />
            <img className='star-wars-planet absolute top-2/4 left-2/3 ' src="../img/ftech2024/planet-2.png" onMouseEnter={() => setPlanetTwo(true)} onMouseLeave={() => setPlanetTwo(false)} />
            <img className='star-wars-planet absolute top-3/4 left-64 -translate-x-2/5 -translate-y-10/12' src="../img/ftech2024/planet-3.png" onMouseEnter={() => setPlanetThree(true)} onMouseLeave={() => setPlanetThree(false)} />

            {(!planetOne && !planetTwo && !planetThree) && (
            <div className='flex justify-center items-center'style={{paddingTop:620}}>
              <div className="max-w-3xl m-auto text-center" >
                <h1 className='text-xl tech-heading text-white'>HOVER OVER A PLANET</h1>
                <h1 className='text-4xl tech-heading text-white'>TO EXPLORE MORE</h1>
              </div>
            </div>
            )}
            

            {planetOne && (
            <div className='absolute top-1/4 left-1/3 h-fit w-96 star-wars-planet-container rounded-md ' onMouseEnter={() => setPlanetOne(true)} onMouseLeave={() => setPlanetOne(false)}>
              <h2 className='tech-heading text-2xl text-white'>The Venue</h2>
              <p className='text-white text-sm'>
              Home to the Valley of Waves and sprawled along the border of the Pilanesberg National Park, Sun City Resort enjoys the fine distinction of being the only surf-and-safari destination within a two-hour drive of landlocked Johannesburg. Sun City Resort is famous for hosting some of the most iconic events in South Africa, including the annual Nedbank Golf Challenge.  
              </p>
              <div className='absolute h-36 w-56 -right-32 -bottom-32'>
                <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/venue image.png" onMouseEnter={() => setPlanetOne(true)} onMouseLeave={() => setPlanetOne(false)} />
              </div>
            </div>)}

            {planetTwo && (
            <div className='absolute top-1/3 right-1/3 h-fit w-96 star-wars-planet-container rounded-md ' onMouseEnter={() => setPlanetTwo(true)} onMouseLeave={() => setPlanetTwo(false)}>
              <h2 className='tech-heading text-2xl text-white'>The Business Conference</h2>
              <p className='text-white text-sm'>
              First Technology executive minds and industry related sponsoring vendors will converge as One Force at the newly designed Convention Centre on Friday, 26 January 2024 and Saturday, 27 January 2024. Each business division will be given the opportunity to present to the audience in an allocated speaking timeslots. These presentations will be followed by roundtable discussions amongst delegates, allowing us to table, collaborate and brainstorm initiatives, pressure points and opportunities. Dedicated breakaway pods will be allocated to each division during the two day event, allowing you to best present your business unit and accompanying innovations in an allocated display area.  
              </p>
              <div className='absolute h-36 w-56 -right-32 -bottom-32'>
                <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/business conference.png" onMouseEnter={() => setPlanetTwo(true)} onMouseLeave={() => setPlanetTwo(false)} />
              </div>
            </div>)}

            {planetThree && (
            <div className='absolute top-2/3 left-1/4 h-fit w-96 star-wars-planet-container rounded-md ' onMouseEnter={() => setPlanetThree(true)} onMouseLeave={() => setPlanetThree(false)}>
              <h2 className='tech-heading text-2xl text-white'>From Grind to Unwind</h2>
              <p className='text-white text-sm'>
              The evening of Saturday, 27 January 2024 will see all stars align at a gala dinner at an iconic location with our group executives, platinum and gold sponsors in attendance as well as entertainment courtesy of one of the finest music acts in the country.
              </p>
              <div className='absolute h-36 w-56 -right-32 -top-32'>
                <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/grind to unwind.jpg" onMouseEnter={() => setPlanetThree(true)} onMouseLeave={() => setPlanetThree(false)} />
              </div>
            </div>)}

          </div>
          <div className='w-full block lg:hidden justify-center items-center my-5' style={{backgroundImage: "url('../img/ftech2024/mobile planets.png')", backgroundSize: 'contain',backgroundRepeat: 'no-repeat', backgroundPosition:'center'}}>
            <div className='h-fit w-96 star-wars-planet-container rounded-md mx-auto my-3'>
            <h2 className='tech-heading text-2xl text-white'>The Venue</h2>
              <p className='text-white text-sm text-shadow'>
              Home to the Valley of Waves and sprawled along the border of the Pilanesberg National Park, Sun City Resort enjoys the fine distinction of being the only surf-and-safari destination within a two-hour drive of landlocked Johannesburg. Sun City Resort is famous for hosting some of the most iconic events in South Africa, including the annual Nedbank Golf Challenge.  
              </p>
            </div>
            <div className='h-36 w-56 mx-auto my-3'>
              <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/venue image.png"/>
            </div>
            <div className='h-fit w-96 star-wars-planet-container rounded-md mx-auto my-3'>
              <h2 className='tech-heading text-2xl text-white'>The Business Conference</h2>
                <p className='text-white text-sm'>
                The evening of Saturday, 27 January 2024 will see all stars align at a gala dinner at an iconic location with our group executives, platinum and gold sponsors in attendance as well as entertainment courtesy of one of the finest music acts in the country.
                </p>
            </div>
            <div className='h-36 w-56 mx-auto my-3'>
              <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/business conference.png"/>
            </div>
            <div className='h-fit w-96 star-wars-planet-container rounded-md mx-auto my-3'>
              <h2 className='tech-heading text-2xl text-white'>From Grind to Unwind</h2>
                <p className='text-white text-sm text-shadow'>
                The evening of Saturday, 27 January 2024 will see all stars align at a gala dinner at an iconic location with our group executives, platinum and gold sponsors in attendance as well as entertainment courtesy of one of the finest music acts in the country.
                </p>
            </div>
            <div className='h-36 w-56 mx-auto my-3'>
              <img className='object-cover w-full h-full rounded-lg border-white-400 border-1' src="../img/ftech2024/grind to unwind.jpg"/>
            </div>
          </div>

      <div className="relative sm:px-10 pb-5 sm:pt-24 mt-24 sm:mx-auto sm:w-8/12">
        <div className="max-w-3xl mx-auto text-center ">
          <div>
            <h1 className="text-3xl text-slate-100 font-bold tech-heading ">AGENDA</h1>
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab>Thursday, 25 January </Tab>
            <Tab>Friday, 26 January </Tab>
            <Tab>Saturday, 27 January </Tab>
            <Tab>Sunday, 28 January </Tab>
          </TabList>
          <TabPanel>
            <table className="table-auto table-agenda">
              <tbody>
                <tr className='table-row'>
                  <td><p>14:00</p></td>
                  <td></td>
                  <td><p>Check in at Cascades Hotel, Sun City</p></td>
                </tr>
                <tr className='table-row'>
                  <td><p>17:00</p></td>
                  <td></td>
                  <td><p>Evening at leisure</p></td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="table-auto table-agenda">
                <tbody>
                  <tr className='table-row'>
                    <td><p>6:30</p></td>
                    <td></td>
                    <td><p>Hotel Breakfast</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>8:00</p></td>
                    <td></td>
                    <td><p>Departure of the Millenium Falcon to conference venue </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>8:30</p></td>
                    <td></td>
                    <td><p>Opening Ceremony - Awakening the Force of Unity</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>9:00</p></td>
                    <td></td>
                    <td><p>Mastering the Force of Collaboration: divisional keynote addresses commence</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>9:00</p></td>
                    <td></td>
                    <td><p>Speakers Begin</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>9:50</p></td>
                    <td></td>
                    <td><p>Council of Technological Excellence: round table discussion with initial 5 speakers </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>10:10</p></td>
                    <td></td>
                    <td><p>Break</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>10:25</p></td>
                    <td></td>
                    <td><p>Next set of Speakers begin</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>11:15</p></td>
                    <td></td>
                    <td><p>Council of Technological Excellence: round table discussion with second set of speakers </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>11:35</p></td>
                    <td></td>
                    <td><p>Next set of Speakers begin</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>12:25</p></td>
                    <td></td>
                    <td><p>Council of Technological Excellence: round table discussion with third set of speakers </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>12:45</p></td>
                    <td></td>
                    <td><p>Lunch Break</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>14:00</p></td>
                    <td></td>
                    <td><p>Next set of Speakers begin</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>14:25</p></td>
                    <td></td>
                    <td><p>Council of Technological Excellence: round table with final two speakers </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>14:40</p></td>
                    <td></td>
                    <td><p>Council close</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>16:00</p></td>
                    <td></td>
                    <td><p>Evening at leisure</p></td>
                  </tr>
                </tbody>
              </table>
          </TabPanel>
          <TabPanel>
            <table className="table-auto table-agenda">
                <tbody>
                  <tr className='table-row'>
                    <td><p>6:30</p></td>
                    <td></td>
                    <td><p>Hotel Breakfast</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>8:00</p></td>
                    <td></td>
                    <td><p>Departure of the Millenium Falcon to conference venue </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>8:30</p></td>
                    <td></td>
                    <td><p>Vendor Speakers Begin</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>10:00</p></td>
                    <td></td>
                    <td><p>Break</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>10:30</p></td>
                    <td></td>
                    <td><p>The Training Grounds of Innovation: Jedi Training Session</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>10:30</p></td>
                    <td></td>
                    <td><p>Delegates to be split into teams for the day </p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>11:00</p></td>
                    <td></td>
                    <td><p>Challenge 1</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>12:00</p></td>
                    <td></td>
                    <td><p>Jedi Lunch break</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>13:00</p></td>
                    <td></td>
                    <td><p>Challenge 2</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>14:00</p></td>
                    <td></td>
                    <td><p>Challenge 3</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>15:00</p></td>
                    <td></td>
                    <td><p>Challenge 4</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>19:00</p></td>
                    <td></td>
                    <td><p>The Force United, The Journey Continues: Canapes, cocktails & entertainment at an    iconic location within the resort</p></td>
                  </tr>
                  <tr className='table-row'>
                    <td><p>20:30</p></td>
                    <td></td>
                    <td><p>Dinner & headline entertainment act at another iconic location within the resort</p></td>
                  </tr>
                </tbody>
              </table>
          </TabPanel>
          <TabPanel>
            <table className="table-auto table-agenda">
              <tbody>
                <tr className='table-row'>
                  <td><p>10:00</p></td>
                  <td></td>
                  <td><p>Check out & departure</p></td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
      <div className="relative sm:px-10 pb-12 sm:pt-5 mt-1 sm:max-w-4xl sm:mx-auto sm:w-10/12">
        <div className="relative py-10 " style={{backgroundColor: "#000"}}>
          <div className="text-center ">
            <div className='my-5'>
              <h1 className="text-4xl text-white font-bold tech-heading mb-5">HOUSE KEEPING</h1>
              <div className='flex justify-center items-center px-5'>
                <p className='text-xl text-white w-full'>
                Ground travel arrangements for those delegates landing at OR Tambo International airport and traveling to Sun City will be confirmed in due course.
                </p>
              </div>
              <div className='flex justify-center items-center px-5'>
                <p className='text-xl text-white w-full'>
                All delegates will be accommodated at the Cascades Hotel, Sun City Resort. Check in takes place on the afternoon of Thursday, 25 January 2023 from 14:00.  Further detail regarding check in will be communicated in due course.
                </p>
              </div>
              <div className='flex justify-center items-center px-5'>
                <p className='text-xl text-white w-full'>
                Please monitor this platform for future communicated event details and updates.
                  We look forward to seeing you and building the First Technology Empire at Sun City.</p>
              </div>
              <div className='flex justify-center items-center px-5'>
                <p className='text-xl text-white w-full'>
                “May the Force be with you”</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
    );
    
};

export default FirstTech24Conference;