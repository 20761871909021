import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import './admin.css'
import DataTable from 'react-data-table-component';
import { getDatabase, ref, set } from 'firebase/database';
import { getFirestore, doc, getDoc, getDocs, updateDoc, collection } from 'firebase/firestore';
import moment from 'moment';

const ChallengeLeaderboard = () => {
  // Get a reference to the database
  const [usersList, setUsersList] = useState({});
  const [fetchedData, setFetchedData] = useState(false);

  useEffect(() => {
    const fetchChallengeAnswers = async () => {
      try {
      const db = getFirestore();
      const challengeAnswerDocs = await getDocs(collection(db, 'challengeAnswers'));
      const answersByPhone = {};

      challengeAnswerDocs.forEach(async (docA) => {
      
        try{
          const challengeAnswerData = docA.data();
          
          const { firstName, phone, answer, challengeId, timestamp } = challengeAnswerData;
          const eventDocRef = doc(db, `events/182d9098-617b-4c11-bcbf-47082ee6a360/challenges`, challengeId);
          const eventDoc = await getDoc(eventDocRef);
  
          
          if (eventDoc.exists()) {
            const eventData = eventDoc.data();
            const correctAnswer = eventData.correctAnswer;
  
            if (!answersByPhone[phone]) {
              answersByPhone[phone] = {
              firstName,
              phone,
              correctCount: 0,
              latestAnswerTime: timestamp, // convert timestamp to relative time string
              answeredChallenges: new Set() // Track answered challenges
              };
            }
  
            if (answer == correctAnswer && !answersByPhone[phone].answeredChallenges.has(challengeId)) {
              answersByPhone[phone].correctCount += 1;
              answersByPhone[phone].answeredChallenges.add(challengeId); // Mark challenge as answered
          }

            console.log(answersByPhone);
            setUsersList(Object.values(answersByPhone).map(user => ({
              ...user,
              latestAnswerTime: moment(user.latestAnswerTime).fromNow(),
            })).sort((a, b) => {
                if (b.correctCount === a.correctCount) {
                    return b.latestAnswerTime.localeCompare(a.latestAnswerTime); // Sort by time if scores are tied, earlier is better
                } else {
                    return b.correctCount - a.correctCount; // Sort by score primarily
                }
            }));
            console.log(usersList);
            setFetchedData(true);
          }
        }
        catch(error)
        {
          console.log('Error fetching challenge answers:', error);
        }
        
      });

      
      } catch (error) {
      console.error('Error fetching challenge answers:', error);
      }
    };

    fetchChallengeAnswers();
  }, []);


  const columns = [
    {
        name: 'Phone',
        selector: row => row.phone.length <= 5 ? row.phone : '*** **' + row.phone.slice(-5), // Mask if longer than 5 digits
        sortable:false,
    },
    {
        name: 'First Name',
        selector: row => row.firstName,
        sortable: true,
    },
    {
        name: 'Correct Answers',
        selector: row => row.correctCount,
        sortable: true,
    },
    {
      name: 'Latest Answer Time',
      selector: row => row.latestAnswerTime,
      sortable: true,
  },
  ];

  return (

    <div className="container-fluid">
        <div className="row flex-nowrap">
            <div className="col py-3">
              <div className="users">
                <div className='row'>
                  <h2>First Espionage</h2>
                  <h3>Challenge Leaderboard</h3>
                </div>
                {fetchedData ? (
                  <div>
                    <div className='row m-1'>
                      
                    </div>
                  
                    <DataTable
                      columns={columns}
                      data={usersList}
                      highlightOnHover 
                      responsive
                    />
                  </div>
                ) : (<div style={{marginTop:50, justifyContent: 'center', display: 'flex'}}>Loading</div>)}
              </div>
            </div>
        </div>
    </div>
    
  );
};

export default ChallengeLeaderboard;