import logo from './logo.svg';
import './App.css';
import React, { Component, useState, useEffect, img, useCallback } from "react";
import { useSearchParams, Routes, Route } from "react-router-dom";
import EventRegister from './pages/events/event-register';
import EventRegisterWaitList from './pages/events/event-register-waitlist';
import Admin from './pages/admin/admin';
import WaitList from './pages/admin/waitlist';
import LoginPage from './pages/user/login';
import FirstTech24Conference from './pages/landing/first-tech-bus-con';
import CommandPromptPage from './pages/landing/first-tech-espionage';
import CommandPromptPageEastLondon from './pages/landing/first-tech-espionage-east-london';
import EventChallenge from './pages/events/challenge-answer';
import ChallengeLeaderboard from './pages/admin/challenge-leaderboard';

function App() {
  return (
    <Routes> {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path='/event-register/:eventId' element={<EventRegister />} ></Route>
      <Route exact path='/event-waitlist/:eventId' element={<EventRegisterWaitList />} ></Route>
      <Route exact path='/event/:eventId/challenges/:challengeId' element={<EventChallenge />} ></Route>
      <Route exact path='/first-tech-2024-business-conference' element={<FirstTech24Conference />} ></Route>
      <Route exact path='/first-tech-espionage-eastlondon' element={<CommandPromptPageEastLondon />} ></Route>
      <Route exact path='/first-tech-espionage' element={<CommandPromptPage />} ></Route>
      <Route exact path='/espionage-leaderboard' element={<ChallengeLeaderboard />} ></Route>
      <Route exact path='/login' element={<LoginPage />} ></Route>
      <Route exact path='/admin' element={<Admin />}>
        <Route
          path="waitlist"
          element={<WaitList />}
        />
      </Route>
    </Routes>
  );
}

export default App;
